// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("jquery")

import '../stylesheets/application'
import "bootstrap"

window.jQuery = $;

async function showSetupError(){
    await $("#setup-git-happy-path").fadeOut().promise();
    await $("#setup-step-1-error").fadeIn().promise();
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

var interval;

function setIntervalWithTurbolinks(intervalFunction, milliseconds) {
    interval = setInterval(intervalFunction, milliseconds);

    $(document).on('turbolinks:before-cache turbolinks:before-render', function() {
      clearTimeout(interval);
    });
  }

document.addEventListener("turbolinks:load", () => {
    feather.replace();
    $('[data-toggle="tooltip"]').tooltip();
    $('.dropdown-toggle').dropdown();
    $("#invite_cancel_at").datepicker({
        startDate: '+1y',
        endDate: '+10y'
    });

    AOS.init();
    window.$ = $;
    var formStep = 0;

    $("#register-submit").click(function (e) {
        $("#tos-error").hide();

        if ($("#tos").is(':checked')) {
            return true;
        } else {
            e.preventDefault();
            $("#tos-error").show();
            return false;
        }
    });

    $("#setup-generate-key").click(function(e) {
        const url = $(this).attr("url");

        $.post(url, function( data ) {
            if(data.status == "error"){
                showSetupError();
            }else if(data.status == "OK"){
                $("#ssh_key").val(data.output);

                var copyText = document.getElementById("ssh_key");
                copyText.select();
                copyText.setSelectionRange(0, 99999);
                document.execCommand("copy");

                $("#setup-generate-key").hide();
            }
        });
    });


    $("#setup-reset-key").click(function (e) {
        $("#setup-error").hide();
        const url = $(this).attr("url");

        $.post(url, function( data ) {
            location.reload();
        });
    });

    $("#setup-check-repo").click(function (e) {
        $("#setup-error").hide();
        const url = $(this).attr("url");
        $("#setup-check-repo").attr("disabled", true);

        $.post(url, function( data ) {
            console.log(data);
            if(data.status == "error"){
                $("#setup-error").show();
            }else if(data.status == "OK"){
                console.log("success!");
                $("#setup-step-1").hide();
                $("#setup-done").show();
                window.scrollTo(0, 0);

                setIntervalWithTurbolinks(function(){
                    $.get(url.replace("check","poll_status"), function( data ) {
                        console.log(data)
                        if (data.status == "ERROR"){
                            clearTimeout(interval);
                            console.log("error!")
                        }else if(data.status == "DONE"){
                            clearTimeout(interval);
                            $("#setup-done").addClass("success-anim-done")
                            console.log("done!")
                        }else{
                            let amount = data.clone_status
                            if(amount == null){
                                amount = 0;
                            }else{
                                amount = parseInt(amount)
                            }

                            $("#clone-amount").text(amount+1);
                        }
                    });
                }, 1000);

                console.log(url)
            }
            $("#setup-check-repo").attr("disabled", false);
        });
    });

    $("#setup-check-repo-pat").click(function (e) {
        $("#setup-error").hide();
        const url = $(this).attr("url");
        $("#setup-check-repo-pat").attr("disabled", true);

        $.post(url, { pat: $("#pat").val(), url: $("#repo_url").val() }, function( data ) {
            console.log(data);
            if(data.status == "error"){
                $("#setup-error").show();
            }else if(data.status == "OK"){
                console.log("success!");
                $("#setup-step-1").hide();
                $("#setup-done").show();
                window.scrollTo(0, 0);

                setIntervalWithTurbolinks(function(){
                    $.get(url.replace("check_pat","poll_status"), function( data ) {
                        console.log(data)
                        if (data.status == "ERROR"){
                            clearTimeout(interval);
                            console.log("error!")
                        }else if(data.status == "DONE"){
                            clearTimeout(interval);
                            $("#setup-done").addClass("success-anim-done")
                            console.log("done!")
                        }else{
                            let amount = data.clone_status
                            if(amount == null){
                                amount = 0;
                            }else{
                                amount = parseInt(amount)
                            }

                            $("#clone-amount").text(amount+1);
                        }
                    });
                }, 1000);

                console.log(url)
            }
            $("#setup-check-repo-pat").attr("disabled", false);
        });
    });

    $("#user_password, #subscriber_password").keyup(function (e) {
        var number = /([0-9])/;
        var alphabets = /([a-z])/;
        var upper_alphabets = /([A-Z])/;
        var special_characters = /([²,³,µ,;,:,<,>,|,',#,+,~,#,?,!,@,$,%,^,&,*,-,_,\\,\",\',\/,€,(,),},{,\[,\]])/;
        $('#password-hint').show();

        if($(this).val().length<6) {
            $('#password-hint').html("Please use at least 6 characters.");
        } else {
            if($(this).val().match(number) && $(this).val().match(alphabets) && $(this).val().match(upper_alphabets) && $(this).val().match(special_characters)) {
                $('#password-hint').hide();
            } else {
                var msg = "Please use ";
                if(!$(this).val().match(alphabets)) { msg += "1 lowercase character, "; }
                if(!$(this).val().match(upper_alphabets)) { msg += "1 uppercase character, "; }
                if(!$(this).val().match(number)) { msg += "1 digit, "; }
                if(!$(this).val().match(special_characters)) { msg += "1 special character, "; }

                msg = msg.replace(/,(?=[^,]*$)/, '').replace(/,(?=[^,]*$)/, ' and');

                $('#password-hint').html(msg.trim() + ".");
            }
        }
    });

    $(".next-step").click(function(){
        window.scrollTo(0,0)
        $(".form-step").hide();
        formStep = formStep + 1;
        $("[data-step='"+formStep+"'].form-step").show();
    });


    $("#show-setup-new-card-button").click(function(){
        $(this).hide();
        $("#setup-new-card").show();
    });


    /* invite multi step flow */

    $("input[required='required']").each(function (){
        $("label[for='" + $(this).attr("id") + "']").addClass("required");
    });

    $("[name='repositories[]']").click(function(){
       if($("[name='repositories[]']:checked").length > 0){
        $("#min-1-repo-error").hide();
        $("#invite-step-1-button").attr("disabled", false);
       }else{
        $("#min-1-repo-error").show();
        $("#invite-step-1-button").attr("disabled", true);
       }
    });

    $("#invite-step-2-button").click(function(){
        $("#error-invalid-email").hide();
        window.scrollTo(0,0)
        if(!validateEmail($("#invite_email").val())){
            $("#error-invalid-email").show();
        }

        let inputs = $("#invite-form-step-1 input");
        let all_filled = true;

        for (let index = 0; index < inputs.length; index++) {
            const input = inputs[index];

            if (input.value == ""){
                all_filled = false;
            }
        }

        if (!all_filled){
            $("#error-fill-all-fields").show();
        }else{
            $("#error-fill-all-fields").hide();

            if(validateEmail($("#invite_email").val())){
                $(".form-step").hide();
                formStep = formStep + 1;
                $("[data-step='"+formStep+"'].form-step").show();
            }
        }
    });

    $("input[name='choose-trigger-style']").change(function(){
        if($(this).val() == "predefined"){
            $("#invite_trigger_event_txt").val("");
            $("#freefill-trigger-event").hide();
            $("#predefined-trigger-events").show();
        }else{
            $("[name='trigger_events[]']").prop("checked", false);
            $("#freefill-trigger-event").show();
            $("#predefined-trigger-events").hide();
        }
    });

    $("input[name='choose-depositor-fee']").change(function(){
        if($(this).val() == "active"){
            $("#show-depositor-fee").show();
        }else{
            $("#invite_service_fee").val(0);
            $("#invite_service_fee").change();
            $("#show-depositor-fee").hide();
        }
    });

    $("#invite_subscription_fee, #invite_service_fee").change(function(){
        $("#fee-42escrow").text("$ " + $("#invite_subscription_fee").val());
        $("#fee-depositor").text("$ " + $("#invite_service_fee").val());
        let sum_val = parseInt($("#invite_subscription_fee").val()) + parseInt($("#invite_service_fee").val());
        $("#fee-total").text("$ " + sum_val);
    });

    $("#invite-submit").click(function (e) {
        $("#error-accept-terms").hide();
        $("#error-choose-trigger").hide();
        $("#error-choose-end-date").hide();
        $("#error-depositor-fee").hide();


        if ($("[name='trigger_events[]']:checked").length == 0 && $("#invite_trigger_event_txt").val() == ""){
            e.preventDefault();
            $("#error-choose-trigger").show();
        }

        if ($("#invite_cancel_at").val() == ""){
            e.preventDefault();
            $("#error-choose-end-date").show();
        }

        if ($("#invite_service_fee").val() < 0 || $("#invite_service_fee").val() > $("#invite_subscription_fee").val()) {
            e.preventDefault();
            $("#error-depositor-fee").show();
        }

        if (!$("#terms-1").is(':checked')) {
            e.preventDefault();
            $("#error-accept-terms").show();
        }

        if (!$("#terms-2").is(':checked')) {
            e.preventDefault();
            $("#error-accept-terms").show();
        }

        if($("#error-accept-terms").is(":visible") ||
           $("#error-choose-trigger").is(":visible") ||
           $("#error-choose-end-date").is(":visible") ||
           $("#error-depositor-fee").is(":visible")){
            window.scrollTo(0, 0);
            return false;
        }
    });

    /* accept terms */


    $("#accept-invite-button").click(function (e) {
        $("#error-accept-terms").hide();

        if (!$("#terms-1").is(':checked')) {
            e.preventDefault();
            $("#error-accept-terms").show();
            return false;
        }

        if (!$("#terms-2").is(':checked')) {
            e.preventDefault();
            $("#error-accept-terms").show();
            return false;
        }
    });

    /* login */

    $(".login-wrapper button").click(function(){
        $(".login-wrapper #login-modal-sub").load($(this).data("load-url") + " #login-form");

        $("#login-modal-index").hide();
        $("#login-modal-sub").show();
    });

    $("#login-modal").click(function(e){
        if (e.target !== this){ return; }
        $("#login-modal").fadeOut();
        $("body").removeClass("locked");
        $("#login-modal-index").show();
        $("#login-modal-sub").hide();
    });

    $("#login-dialog").click(function(e){
        e.preventDefault();
        $("#login-modal").fadeIn(250);
        $("body").addClass("locked");
    });

    $("#show-counterproposal").click(function(e){
        e.preventDefault();
        $("#happy-case-actions").hide();
        $("#counterproposal-form").fadeIn(250);
    });

    $("[data-slack-form] button").click(function(e){
        let token = $(this).data("token");
        let formid = $(this).data("form-id");
        $(this).attr("disabled", true);

        let inputs = $(this).closest("[data-slack-form]").find(':input');

        let message = ""

        $.each( inputs, function( index, input ){
          if (input.value.length > 0) {
            message += input.name + ":" + input.value + "\n";
          }
        });

        $.post("/post_to_slack", { authenticity_token: token, message: message, formid: formid }).done(function( data ) {
          $(".form-box").animate({ left: '-100%'}, 'medium');
        });

        e.preventDefault ? e.preventDefault() : (e.returnValue = false); //IE11 fix
      });

    if($("#frontpage-container-news").length > 0){
        const sizes = ["350x350", "350x350", "97x97", "97x97", "97x97"]
        $.get( "/blog", function( data ) {
            $.each($( ".blog-item", data), function( index, value ) {
                if(index > 4) {return;}
                let bg_img = $(".blog-image", value).css("background-image").match(/url\(["']?([^"']*)["']?\)/)[1];
                let new_size = sizes[index];

                $("#frontpage-container-news .blog-image")[index].src = bg_img.replace(/com(.)+?\//, "com/"+new_size+"/");
                $("#frontpage-container-news h5")[index].innerHTML = $("h2", value).text();
                $("#frontpage-container-news p")[index].innerHTML = $("a", value).text();
                $("#frontpage-container-news a")[index].href = $("a", value).attr("href");
            });
        });
    }

    if($(".sb-lazy-load").length > 0 ){
        $.each($(".sb-lazy-load"), function(index, value) {
            let source = $(value).attr("src");
            let new_image_1x = source.replace(/com(.)+?\//, "com/" + Math.floor($(value).width()) + "x" + Math.floor($(value).height()) +"/");
            let new_image_2x = source.replace(/com(.)+?\//, "com/" + Math.floor($(value).width() * 2) + "x" + Math.floor($(value).height() * 2) +"/");
            let srcset = new_image_1x + " 1x, "+ new_image_2x +" 2x";

            $(value).attr("src", new_image_1x);
            $(value).attr("srcset", srcset);
        });
    }
});


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
